/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import * as animationData from "../assets/lottie/loading.json"
import Lottie from "react-lottie"

const LanguageRedirect = () => {
	const { i18n } = useTranslation()
	const navigate = useNavigate()
	const supportedLanguages = ["en", "ko"] // add your supported languages here
	const fallbackLanguage = "en"

	useEffect(() => {
		let detectedLanguage = i18n.language
		const currentPath = window.location.pathname
		if (detectedLanguage.length > 2) {
			detectedLanguage = detectedLanguage.substring(0, 2)
		}
		if (!supportedLanguages.includes(detectedLanguage)) {
			detectedLanguage = fallbackLanguage
		}
		if (!currentPath.startsWith(`/${detectedLanguage}`)) {
			navigate(`/${detectedLanguage}${currentPath}`)
		}
	}, [i18n.language, navigate])
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	}
	return (
		<div
			style={{
				display: "flex",
				flex: 1,
				justifyContent: "center",
				alignContent: "center",
				alignItems: "center",
				minWidth: "100vw",
				minHeight: "100vh",
			}}>
			<Lottie options={defaultOptions} height={"5%"} width={"5%"} />
		</div>
	)
}

export default LanguageRedirect
