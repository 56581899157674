import React from "react"
import { ReactComponent as Star } from "../assets/icons/star-06.svg"
import { ReactComponent as DownloadIcon } from "../assets/icons/download-03.svg"
import { ReactComponent as QuestionIcon } from "../assets/icons/message-question-square.svg"
import { COLORS, FONTS } from "../constants/constants"
import Icon from "@ant-design/icons"
import { Col, Row } from "antd"
import Text from "./Text"

const tagsData: any = {
	features: {
		title: "FEATURES",
		icon: (
			<Icon
				component={() => (
					<Star
						width={"1rem"}
						height={"1rem"}
						stroke={COLORS.purple}
						style={{ marginTop: "0.1rem" }}
					/>
				)}
			/>
		),
	},
	download: {
		title: "DOWNLOAD",
		icon: (
			<Icon
				component={() => (
					<DownloadIcon
						width={"1rem"}
						height={"1rem"}
						stroke={COLORS.purple}
						style={{ marginTop: "0.05rem" }}
					/>
				)}
			/>
		),
	},
	faqs: {
		title: "FAQs",
		icon: (
			<Icon
				component={() => (
					<QuestionIcon
						width={"1rem"}
						height={"1rem"}
						stroke={COLORS.purple}
						style={{ marginTop: "0.05rem" }}
					/>
				)}
			/>
		),
	},
}
interface IProps {
	tag: string
}
const Tags: React.FC<IProps> = ({ tag }) => {
	return (
		<div
			style={{
				paddingTop: "0.625rem",
				paddingBottom: "0.625rem",
				paddingLeft: "1.125rem",
				paddingRight: "1.125rem",
				backgroundColor: COLORS.purple50,
				borderRadius: 100,
				display: "flex",
				flexDirection: "row",
				flexGrow: 1,
				flexShrink: 1,
				flexBasis: "auto",
				width: "fit-content",
			}}>
			{tagsData[tag].icon}
			<Text
				style={{
					marginLeft: "0.5rem",
					color: COLORS.purple,
					fontSize: "1rem",
					fontWeight: "600",
					fontFamily: FONTS.enMain,
				}}>
				{tagsData[tag].title}
			</Text>
		</div>
	)
}

export default Tags
