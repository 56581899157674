import { Col, Grid, Row } from "antd"
import React from "react"
import Text from "./Text"
import { COLORS, FONTS } from "../constants/constants"
import Icon from "@ant-design/icons"
import { ReactComponent as Mail } from "../assets/icons/mail-01.svg"
import Link from "antd/es/typography/Link"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import TransText from "./TransText"

const StyledLink = styled(Link)`
	color: ${COLORS.white} !important;
	font-weight: 400;
	font-family: ${FONTS.enMain};
	text-decoration: underline !important;
`
const { useBreakpoint } = Grid

export default function FooterContent() {
	const { t } = useTranslation()
	const { lg } = useBreakpoint()

	return (
		<Col span={22} offset={1}>
			<Row align={"middle"}>
				<Col
					xs={{
						span: 24,
					}}
					lg={{
						span: 6,
					}}
					style={{ textAlign: lg ? "left" : "center" }}>
					<img
						src={require("../assets/img/logo.png")}
						width={"120px"}
						alt={"logo"}
					/>
				</Col>
				<Col
					xs={{
						span: 24,
					}}
					lg={{
						span: 14,
						offset: 4,
					}}>
					{lg ? (
						<Row align={"bottom"} justify={"end"}>
							<StyledLink
								href={`${window.location.pathname}//terms`}
								target="_blank">
								{t("footer.terms")}
							</StyledLink>
							<div style={{ marginLeft: "3rem" }} />
							<StyledLink
								href={`${window.location.pathname}//privacy`}
								target="_blank">
								{t("footer.privacy")}
							</StyledLink>
							<div style={{ marginLeft: "6rem" }} />
							<Row align={"bottom"}>
								<Icon
									component={() => (
										<Mail
											width={"1rem"}
											height={"1rem"}
											stroke={COLORS.white}
										/>
									)}
								/>
								<div style={{ marginLeft: "0.3rem" }} />
								<Text
									textProps={{
										family: FONTS.enMain,
										color: COLORS.white,
									}}>
									info@talkiverse.com
								</Text>
							</Row>
							<div style={{ marginLeft: "6rem" }} />
							<Text
								textProps={{
									family: FONTS.enMain,
									color: COLORS.white,
								}}>
								2024 © Milli Inc. Talkiverse
							</Text>
						</Row>
					) : (
						<>
							<div style={{ marginTop: "2rem" }} />
							<Col style={{ textAlign: "center" }}>
								<StyledLink
									href={`${window.location.pathname}/terms`}
									target="_blank">
									{t("footer.terms")}
								</StyledLink>
								<div style={{ marginTop: "0.5rem" }} />
								<StyledLink
									href={`${window.location.pathname}/privacy`}
									target="_blank">
									{t("footer.privacy")}
								</StyledLink>
								<div style={{ marginTop: "2rem" }} />
								<Row align={"bottom"} justify={"center"}>
									<Icon
										component={() => (
											<Mail
												width={"1rem"}
												height={"1rem"}
												stroke={COLORS.white}
											/>
										)}
									/>
									<div style={{ marginLeft: "0.3rem" }} />
									<TransText
										trans={"info@talkiverse.com"}
										textProps={{
											// family: FONTS.enMain,
											color: COLORS.white,
										}}
									/>
								</Row>
								<div style={{ marginTop: "2rem" }} />
								<Text
									textProps={{
										family: FONTS.enMain,
										color: COLORS.white,
									}}>
									2024 © Milli Inc. Talkiverse
								</Text>
							</Col>
						</>
					)}
				</Col>
			</Row>
		</Col>
	)
}
