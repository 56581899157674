import { Col, Divider, Grid, Row } from "antd"
import Header from "../components/Header"
import FloatingNav from "../components/FloatingNav"
import TransText from "../components/TransText"
import ColorChangingDiv from "../components/ColorChangingDiv"
import { COLORS, FONTS } from "../constants/constants"
import Trail from "../components/TrailedComponent"
import Faqs from "../components/Faqs"
import Footer from "../components/Footer"
import FooterContent from "../components/FooterContent"
import Tags from "../components/Tags"
import FeaturesCard from "../components/FeaturesCard"
import background from "../assets/img/background.png"
import { useTranslation } from "react-i18next"
const faqQusetionList = ["faq.q1", "faq.q2", "faq.q3", "faq.q4", "faq.q5"]
const faqAnswerList = ["faq.a1", "faq.a2", "faq.a3", "faq.a4", "faq.a5"]

const { useBreakpoint } = Grid

const Landing = () => {
	const { lg } = useBreakpoint()
	const { i18n } = useTranslation()
	return (
		<Col
			span={24}
			style={{
				backgroundImage: `url(${background})`,
				backgroundRepeat: "no-repeat",
				backgroundPositionX: "center",
				backgroundPositionY: lg ? "14rem" : "5rem",
			}}>
			{lg && (
				<Header>
					<FloatingNav />
				</Header>
			)}
			<Col
				id={"home"}
				xs={{
					span: 22,
					offset: 1,
				}}
				lg={{
					span: 18,
					offset: 3,
				}}
				xl={{
					span: 18,
					offset: 3,
				}}
				xxl={{
					span: 16,
					offset: 4,
				}}
				// span={12}
				// offset={6}
				style={{
					textAlign: "center",
					marginTop: lg ? "14rem" : "5rem",
					marginBottom: "14rem",
				}}>
				<TransText
					trans={"home.heroText"}
					className="fontHighlight"
					textProps={{
						family: FONTS.enMain,
						size: lg ? "10rem" : "4rem",
						weight: "800",
						color: COLORS.black,
					}}
					style={{
						lineHeight: 0.8,
						letterSpacing: -2,
					}}
				/>
				<div style={{ marginTop: "5rem" }} />
				<Col
					xs={{
						span: 22,
						offset: 1,
					}}
					lg={{
						span: 18,
						offset: 3,
					}}
					xl={{
						span: 18,
						offset: 3,
					}}
					xxl={{
						span: 16,
						offset: 4,
					}}>
					<img
						alt={"App introduction"}
						src={
							i18n.resolvedLanguage === "ko"
								? require("../assets/img/homeKR.png")
								: require("../assets/img/homeEN.png")
						}
						width={"100%"}
					/>
				</Col>
				<div style={{ marginTop: "10rem" }} />
				<Row align={"middle"}>
					<Col
						xs={{
							span: 22,
							offset: 1,
						}}
						lg={{
							span: 4,
							offset: 2,
						}}
						style={{ textAlign: "left" }}>
						<ColorChangingDiv />
					</Col>
					<Col
						xs={{
							span: 22,
							offset: 1,
						}}
						lg={{
							span: 16,
							offset: 2,
						}}
						style={{ textAlign: "left" }}>
						<TransText
							trans={"home.heroDesc"}
							style={{
								lineHeight: lg ? "2.375rem" : "1.5rem",
								marginTop: lg ? 0 : "2rem",
							}}
							textProps={{
								family: "Inter",
								size: lg ? "1.875rem" : "1.2rem",
								weight: "400",
								color: COLORS.gray500,
							}}
						/>
					</Col>
				</Row>
				<div style={{ marginTop: "10rem" }} />
				<TransText
					trans={"home.featureHeader"}
					style={{ lineHeight: lg ? "4.5rem" : "3.5rem" }}
					textProps={{
						size: lg ? "3.75rem" : "2rem",
						weight: "800",
					}}
				/>
			</Col>
			<div style={{ marginTop: "10rem" }} />
			<Col
				xs={{
					span: 22,
					offset: 1,
				}}
				lg={{
					span: 18,
					offset: 3,
				}}
				xl={{
					span: 18,
					offset: 3,
				}}
				xxl={{
					span: 16,
					offset: 4,
				}}
				id={"features"}>
				<Tags tag={"features"} />
				<div style={{ marginTop: "1.25rem" }} />
				<Trail>
					<FeaturesCard category={"privateConvo"} />
				</Trail>
				<div style={{ marginTop: "1rem" }} />
				<Trail>
					<FeaturesCard category={"channel"} />
				</Trail>
				<div style={{ marginTop: "1rem" }} />
				<Trail>
					<FeaturesCard category={"multilang"} />
				</Trail>
			</Col>
			<div style={{ marginTop: "3rem" }} />
			<Col
				xs={{
					span: 22,
					offset: 1,
				}}
				lg={{
					span: 18,
					offset: 3,
				}}
				xl={{
					span: 18,
					offset: 3,
				}}
				xxl={{
					span: 16,
					offset: 4,
				}}>
				<TransText
					trans={"home.midHero"}
					style={{ lineHeight: lg ? "7.5rem" : "3rem" }}
					textProps={{
						size: lg ? "7.5rem" : "3rem",
						weight: "800",
					}}
				/>
			</Col>
			<div style={{ marginTop: "3rem" }} />
			<Col
				id={"download"}
				xs={{
					span: 22,
					offset: 1,
				}}
				lg={{
					span: 18,
					offset: 3,
				}}
				xl={{
					span: 18,
					offset: 3,
				}}
				xxl={{
					span: 16,
					offset: 4,
				}}
				style={{
					borderRadius: 40,
					paddingTop: "3.75rem",
					paddingBottom: "3.75rem",
					backgroundColor: COLORS.darkBg,
				}}>
				<Col
					span={22}
					offset={1}
					style={{
						textAlign: "left",
					}}>
					<Tags tag={"download"} />
					<Col span={24}>
						<div style={{ marginTop: "2rem" }} />
						<TransText
							// trans={"Join Talkiverse Today!"}
							trans={"download.header"}
							style={{ lineHeight: lg ? "4rem" : "3rem" }}
							textProps={{
								color: COLORS.white,
								size: lg ? "3.75rem" : "3rem",
								weight: "600",
								family: FONTS.enMain,
							}}
						/>
						<div style={{ marginTop: "2rem" }} />
						<TransText
							trans={"download.content"}
							style={{ lineHeight: lg ? "2.375rem" : "1.6rem" }}
							textProps={{
								size: lg ? "1.875rem" : "1.2rem",
								weight: "400",
								color: COLORS.white,
							}}
						/>
					</Col>
					<Col span={12}></Col>
				</Col>
			</Col>
			<div style={{ marginTop: "1rem" }} />
			<Col
				id={"faqs"}
				xs={{
					span: 22,
					offset: 1,
				}}
				lg={{
					span: 18,
					offset: 3,
				}}
				xl={{
					span: 18,
					offset: 3,
				}}
				xxl={{
					span: 16,
					offset: 4,
				}}
				style={{
					textAlign: "left",
					backgroundColor: COLORS.grayBackground,
					borderRadius: 40,
				}}>
				<Row
					align={"middle"}
					style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
					<Col
						xs={{
							span: 22,
							offset: 1,
						}}
						lg={{
							span: 8,
							offset: 2,
						}}
						span={8}
						offset={2}>
						<Tags tag={"faqs"} />
						<div style={{ marginTop: "1.5rem" }} />
						<TransText
							trans={"faq.header"}
							style={{ lineHeight: "2.75rem" }}
							textProps={{
								size: "2.25rem",
								weight: "600",
							}}
						/>
						<div style={{ marginTop: "1.5rem" }} />
						<TransText
							trans={"faq.desc"}
							style={{ lineHeight: "1.875rem" }}
							textProps={{
								size: "1.25rem",
								weight: "400",
							}}
						/>
					</Col>
					<Col
						xs={{
							span: 22,
							offset: 1,
						}}
						lg={{
							span: 11,
							offset: 2,
						}}
						style={{ marginTop: lg ? 0 : "2rem" }}>
						{faqQusetionList.map((q, i) => (
							<>
								<Faqs title={q} content={faqAnswerList[i]} />
								{i < faqQusetionList.length - 1 && <Divider />}
							</>
						))}
					</Col>
				</Row>
			</Col>
			<div style={{ marginTop: "3rem" }} />
			<Footer
				style={{
					background: COLORS.darkBg,
					position: "relative",
					borderTopLeftRadius: 40,
					borderTopRightRadius: 40,
				}}>
				<FooterContent />
			</Footer>
		</Col>
	)
}

export default Landing
