import React, { useState, useTransition } from "react"
import type { CollapseProps } from "antd"
import { Col, Collapse, Row, Space } from "antd"
import { useTranslation } from "react-i18next"
import TransText from "./TransText"
import { COLORS } from "../constants/constants"
import { ReactComponent as Chev } from "../assets/icons/chevron-down.svg"
import Icon from "@ant-design/icons"
import styled from "styled-components"
import useMeasure from "react-use-measure"
import { useSpring, animated, config } from "@react-spring/web"

interface IProps {
	title: string
	content: string
}

const Faqs: React.FC<IProps> = ({ title, content }) => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)
	const toggle = () => {
		setIsOpen(!isOpen)
	}
	const [measureRef, { height }] = useMeasure()

	const styles = useSpring({
		config: config.stiff,
		from: {
			height: 0,
		},
		to: {
			height: isOpen ? height : 0,
		},
	})

	const rotateStyle = useSpring({
		transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
	})

	return (
		<Col
			span={24}
			style={{
				paddingTop: "1rem",
				paddingBottom: "1rem",
				cursor: "pointer",
			}}>
			<Row onClick={toggle} align={"middle"}>
				<Col span={22}>
					<TransText
						trans={title}
						style={{ lineHeight: "1.875rem" }}
						textProps={{
							size: "1.5rem",
							weight: "500",
							color: COLORS.black,
						}}
					/>
				</Col>
				<Col span={1} offset={1}>
					<Icon
						component={() => (
							<animated.div style={{ ...rotateStyle }}>
								<Chev
									width={"1.2rem"}
									height={"1.2rem"}
									stroke={COLORS.black}
								/>
							</animated.div>
						)}
					/>
				</Col>
			</Row>
			<animated.div style={{ overflow: "hidden", ...styles }}>
				<Col
					ref={measureRef}
					span={24}
					style={{
						paddingTop: "0.5rem",
						paddingBottom: "0.5rem",
					}}>
					<TransText
						trans={content}
						style={{ lineHeight: "1.875rem" }}
						textProps={{
							size: "1rem",
							weight: "400",
							color: COLORS.gray500,
						}}
					/>
				</Col>
			</animated.div>
		</Col>
	)
}

export default Faqs
