export const LANGUAGES = [
	{ label: "English", code: "en" },
	{ label: "한국어", code: "ko" },
]

export const COLORS = {
	darkBg: "#101828",
	white: "#fff",
	black: "#0C111D",
	purple: "#5D3FCA",
	neon: "#CEEE00",
	purple300: "#927EDB",
	purple50: "#EFECFA",
	purpleLight: "#F4EBFF",
	neonLight: "#F4EBFF",
	gray500: "#667085",
	gray400: "#98A2B3",
	gray300: "#D0D5DD",
	gray100: "#F2F4F7",
	grayBackground: "#F3F4F6",
	purpleBackground: "#F3F4F6",
	textDarkGray: "#475467",
	// your colors
}

export const FONTS = {
	enMain: "Overused Grotesk",
	koMain: "Spoqa Han Sans Neo",
	enSub: "Satoshi",
	koSub: "D2Coding",
}
