import React from "react"
import "./App.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Error from "./pages/Error"
import { ConfigProvider } from "antd"
import { COLORS } from "./constants/constants"
import Landing from "./pages/Landing"
import PrivacyEN from "./pages/PrivacyEN"
import PrivacyKR from "./pages/PrivacyKR"
import TermsEN from "./pages/TermsEN"
import TermsKR from "./pages/TermsKR"
import LanguageRedirect from "./pages/LangRouter"
// import Channel from "./pages/Channel"

const router = createBrowserRouter([
	{
		path: "/",
		element: <LanguageRedirect />,
		errorElement: <Error />,
	},
	{
		path: "/:lang",
		element: <Landing />,
		errorElement: <Error />,
	},
	{
		path: "/en/privacy",
		element: <PrivacyEN />,
		errorElement: <Error />,
	},
	{
		path: "/ko/privacy",
		element: <PrivacyKR />,
		errorElement: <Error />,
	},
	{
		path: "/en/terms",
		element: <TermsEN />,
		errorElement: <Error />,
	},
	{
		path: "/ko/terms",
		element: <TermsKR />,
		errorElement: <Error />,
	},
	// {
	// 	path: "/",
	// 	element: <Home />,
	// 	errorElement: <Error />,
	// },
	// {
	// 	path: "/login",
	// 	element: <Signin />,
	// },
	// {
	// 	path: "/signup",
	// 	element: <Signup />,
	// },
])

function App() {
	return (
		<>
			<ConfigProvider
				theme={{
					components: {
						Input: {
							colorPrimary: COLORS.purple,
							colorBorder: COLORS.gray300,
							controlOutlineWidth: 1,
							borderRadius: 16,
							controlHeight: 40,
							algorithm: true, // Enable algorithm
						},
						DatePicker: {
							colorPrimary: COLORS.purple,
							colorBorder: COLORS.gray300,
							controlOutlineWidth: 1,
							borderRadius: 16,
							controlHeight: 40,
							colorText: COLORS.black,
							algorithm: true,
						},
						Checkbox: {
							colorPrimary: COLORS.purple,
							colorPrimaryHover: COLORS.purple,
							colorBorder: COLORS.gray500,
							borderRadius: 4,
							algorithm: true,
						},
						Typography: {
							colorLink: COLORS.purple,
							colorLinkHover: COLORS.purple300,
						},
					},
				}}>
				<RouterProvider router={router} />
			</ConfigProvider>
		</>
	)
}

export default App
