import React from "react"
import { useInView, animated } from "@react-spring/web"
interface IProps {
	style?: React.CSSProperties
	children: any
}
const Trail: React.FC<IProps> = ({ children }) => {
	const [ref, inView] = useInView(
		() => ({
			from: {
				opacity: 0,
				y: 80,
			},
			to: {
				config: { mass: 5, tension: 300, friction: 200 },

				opacity: 1,
				y: 0,
			},
		}),
		{
			rootMargin: "-45% 0px -45% 0px",
			// threshold: 5,
			once: true,
		}
	)

	// const props = useSpring({
	// 	opacity: inView ? 1 : 0,
	// 	transform: inView ? "translateX(0)" : "translateX(-50%)",
	// 	config: { mass: 5, tension: 2000, friction: 200 },
	// })

	return (
		<animated.div ref={ref} style={inView}>
			{children}
		</animated.div>
	)
}

export default Trail
