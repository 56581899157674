import { Col, Row } from "antd"
import React from "react"
import { COLORS } from "../constants/constants"
import TransText from "./TransText"
import { useTranslation } from "react-i18next"

interface IProps {
	category: string
}

const assets: any = {
	privateConvo: {
		title: "featurePc.header",
		desc: "featurePc.desc",
		imgEn: require("../assets/img/private_conversation_en.png"),
		imgKr: require("../assets/img/private_conversation_kr.png"),
	},
	channel: {
		title: "featureChannels.header",
		desc: "featureChannels.desc",
		imgEn: require("../assets/img/channels_en.png"),
		imgKr: require("../assets/img/channels_kr.png"),
	},
	multilang: {
		title: "featureMultiLingual.header",
		desc: "featureMultiLingual.desc",
		imgEn: require("../assets/img/multilang_en.png"),
		imgKr: require("../assets/img/multilang_en.png"),
	},
}

const FeaturesCard: React.FC<IProps> = ({ category }) => {
	const { i18n } = useTranslation()
	return (
		<Col
			span={24}
			style={{
				backgroundColor: COLORS.grayBackground,
				borderRadius: 16,
				textAlign: "left",
				padding: "1rem",
			}}>
			<Row align={"middle"}>
				<Col
					xs={{
						span: 24,
					}}
					lg={{
						span: 12,
						order: category === "channel" ? 2 : 1,
					}}
					style={{
						width: "100%",
						objectFit: "contain",
						borderRadius: 16,
						backgroundColor: COLORS.white,
						padding: "2rem",
					}}>
					<img
						alt="description"
						src={
							i18n.resolvedLanguage === "ko"
								? assets[category]["imgKr"]
								: assets[category]["imgEn"]
						}
						width={"100%"}
					/>
				</Col>
				<Col
					xs={{
						span: 24,
					}}
					lg={{
						span: 12,
						order: category === "channel" ? 1 : 2,
					}}
					style={{
						paddingTop: "4rem",
						paddingBottom: "4rem",
					}}>
					<Col span={18} offset={3}>
						<TransText
							trans={assets[category]["title"]}
							textProps={{
								size: "2.25rem",
								weight: "600",
								family: "Inter",
								color: COLORS.black,
							}}
						/>
						<div style={{ marginTop: "2.25rem" }} />
						<TransText
							trans={assets[category]["desc"]}
							style={{ lineHeight: "1.875rem" }}
							textProps={{
								family: "Inter",
								size: "1.25rem",
								weight: "400",
								color: COLORS.gray500,
							}}
						/>
					</Col>
				</Col>
			</Row>
		</Col>
	)
}

export default FeaturesCard
