import React, { useState, useEffect } from "react"
import useMeasure from "react-use-measure"
import { useSpring, useTrail, animated } from "@react-spring/web"
import { COLORS, FONTS } from "../constants/constants"
import { Col } from "antd"
import styled from "styled-components"

const ColorChangingDiv = () => {
	const keywords = ["Discover", "Connect", "Chat"]
	const colors = [COLORS.neon, COLORS.purpleLight, COLORS.neonLight] // Define your colors here
	const [index, setIndex] = useState(0)
	const [open, setopen] = useState(false)
	const [ref, { width, height }] = useMeasure()
	const props = useSpring({ width: open ? width : 0 })

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTimeout(() => setopen(false), 1000)
		}, 4000) // Change every 4 seconds

		return () => clearInterval(intervalId)
	}, [])

	useEffect(() => {
		open && setIndex((prevIndex) => (prevIndex + 1) % colors.length)
		!open &&
			setTimeout(() => {
				setopen(true)
			}, 1000)
	}, [open])

	const trails = useTrail(1, {
		config: { mass: 5, tension: 3700, friction: 200 },
		opacity: open ? 1 : 0,
		x: open ? 0 : -20,
		height: height,
		from: { opacity: 0, x: -20, height: height },
	})

	const Fill = styled(animated.div)<{ $index: number }>`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${colors[index]};
		border-radius: 1rem;
	`
	const Content = styled(animated.div)`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.875rem;
		font-weight: 500;
	`
	const Container = styled(Col)`
		height: 3.875rem;
		border-radius: 1rem;
	`

	return (
		<Container ref={ref} span={24}>
			<Fill $index={index} style={props} />
			{trails.map(({ ...style }) => (
				<Content style={style}>{keywords[index]}</Content>
			))}
		</Container>
	)
}

export default ColorChangingDiv
