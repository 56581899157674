import { Col } from "antd"
import React from "react"

interface IProps {
	style?: React.CSSProperties
	children: any
}

const Header: React.FC<IProps> = ({ style, children }) => {
	return (
		<Col span={8} offset={8} style={{ padding: "2em 0" }}>
			{children}
		</Col>
	)
}

export default Header
