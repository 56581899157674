import { Col, Grid, Row } from "antd"
import React from "react"
import styled from "styled-components"
import { COLORS } from "../constants/constants"
import Icon from "@ant-design/icons"
import { ReactComponent as Logo } from "../assets/img/logo-small.svg"
import TransText from "./TransText"

const Container = styled(Col)`
	background-color: ${COLORS.white};
	box-shadow: 0px 3px 48px rgba(16, 24, 40, 0.18);
	border-radius: 16px;
	padding: 1.5rem;
	font-size: 0.687rem;
	color: ${COLORS.textDarkGray};
	font-weight: 400;
	position: fixed;
	width: 100%;
	z-index: 999;
`
const { useBreakpoint } = Grid
const FloatingNav = () => {
	const { lg } = useBreakpoint()
	return (
		<Container
			xs={{
				span: 22,
			}}
			lg={{
				span: 8,
			}}>
			<Row justify={"space-around"} align={"middle"}>
				<Col
					onClick={(e) => {
						e.preventDefault()
						document.getElementById("home")?.scrollIntoView()
					}}
					style={{
						cursor: "pointer",
					}}>
					<TransText
						trans={"navigation.home"}
						textProps={{
							weight: "500",
							color: COLORS.textDarkGray,
						}}
					/>
				</Col>
				<Col
					onClick={(e) => {
						e.preventDefault()
						document.getElementById("features")?.scrollIntoView()
					}}
					style={{
						cursor: "pointer",
					}}>
					<TransText
						trans={"navigation.features"}
						textProps={{
							weight: "500",
							color: COLORS.textDarkGray,
						}}
					/>
				</Col>
				<Col
					style={{
						cursor: "pointer",
					}}
					onClick={(e) => {
						e.preventDefault()
						document.getElementById("home")?.scrollIntoView()
					}}>
					<Icon
						component={() => <Logo width={"46"} height={"32"} />}
					/>
				</Col>
				<Col
					onClick={(e) => {
						e.preventDefault()
						document.getElementById("download")?.scrollIntoView()
					}}
					style={{
						cursor: "pointer",
					}}>
					<TransText
						trans={"navigation.download"}
						textProps={{
							weight: "500",
							color: COLORS.textDarkGray,
						}}
					/>
				</Col>
				<Col
					onClick={(e) => {
						e.preventDefault()
						document.getElementById("faqs")?.scrollIntoView()
					}}
					style={{
						cursor: "pointer",
					}}>
					<TransText
						trans={"navigation.faqs"}
						textProps={{
							weight: "500",
							color: COLORS.textDarkGray,
						}}
					/>
				</Col>
			</Row>
		</Container>
	)
}

export default FloatingNav
